<template>
  <div class="service_and_support_list">
    <el-input
      placeholder="搜尋Bareiss技術相關的問題"
      v-model="searchKwrod"
      @keyup.enter.native="onSubmit"
      clearable
    >
      <i
        slot="suffix"
        @click="onSubmit"
        class="el-input__icon el-icon-search"
      ></i>
    </el-input>
    <div class="listView">
      <template v-if="noActive">
        <router-link
          :to="`/knowledge_base_list?id=${item.id}`"
          class="list-item"
          v-for="(item, index) in list"
          :key="index"
          :class="item.id == activeId ? 'active' : ''"
        >
          <div class="imgView">
            <img :src="item.pic" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </router-link>
      </template>

      <template v-else>
        <router-link
          :to="`/knowledge_base_list?id=${item.id}`"
          class="list-item"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="imgView">
            <img :src="item.pic" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //搜索类型 默认当前页面搜索=false 跳转=true
    searchType: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //高亮id
    activeId: {
      type: String | Number,
      default() {
        return "";
      },
    },
    //是否启动高亮
    noActive: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //数据
    // data: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
  },
  //监听路由变化
  watch: {
    /**
     * 监听路由
     */
    $route: {
      handler: function (to, form) {
        if (to.query?.keyword) {
          this.searchKwrod = to.query.keyword;
        }
      },
      //立即先去执行handler方法
      immediate: true,
    },
  },
  data() {
    return {
      searchKwrod: "",
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**huo */
    getData() {
      this.$http.knowledgeCate({ parent_id: 0 }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //回车搜索
    onSubmit() {
      if (this.searchKwrod == "") {
        this.$router.push({ path: "/knowledge_base" });
      }
      this.$emit("change", this.searchKwrod);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.el-icon-search {
  cursor: pointer;
  &:hover {
    color: #0075c1;
  }
}
.service_and_support_list {
  padding: 20px 0 50px;
  @include respond-to(sm) {
    padding: 10px 0 20px;
  }
  /deep/ .el-input {
    width: 670px;
    display: block;
    margin: 0 auto 70px;
    @include respond-to(sm) {
      width: 100%;
      margin: 0 auto 20px;
    }
    .el-input__inner {
      border-radius: 20px;
    }
  }
  .listView {
    display: flex;
    justify-content: space-between;
    text-align: center;
    box-sizing: border-box;
    padding: 0 150px;
    @include respond-to(lg) {
      padding: 0 100px;
    }
    @include respond-to(md) {
      padding: 0 50px;
    }
    @include respond-to(sm) {
      padding: 0;
    }
    .list-item {
      span {
        font-size: 20px;
        @include respond-to(lg) {
          font-size: 16px;
        }
        @include respond-to(sm) {
          font-size: 12px;
        }
      }
      &.active {
        span {
          color: #0075c1;
        }
      }
      .imgView {
        margin-bottom: 15px;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
</style>